<!-- 道路测速 -- 模块入口 -->
<template>
  <div id="velocityMeasurement-moduleIndex">
    <div class="header">
        <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
        <!-- <my-navdar :list="list"></my-navdar> -->
        <a-menu :selectedKeys="$route.name?[$route.name]:[]" mode="horizontal" @click="suibianlail">
            <a-menu-item key="ENT_EQUIPMENT1">设备</a-menu-item>
            <a-menu-item key="ENT_EQUIPMENT2">记录</a-menu-item>
            <!-- <a-menu-item key="ENT_EQUIPMENT9">设置</a-menu-item> -->
            <a-menu-item key="ENT_EQUIPMENT3">实际数据</a-menu-item>
        </a-menu>
    </div>
    <div class="main">
        <transition name="slide-fade" mode="out-in">
            <keep-alive>
                <router-view class="view"></router-view>
            </keep-alive>
        </transition>
    </div>
  </div>
</template>

<script>
import indexTitle from '../../components/Title/indexTitle'
import Navdar from '../../components/Navdar/horizontal'

export default {
    name:'velocityMeasurement-moduleIndex',
    components: {
        'my-indextitle': indexTitle,
        'my-navdar': Navdar
    },
    data() {
        return {
            imgurl: require('../../../public/images/velocityMeasurement/velocityMeasurement.png'),
            list: JSON.parse(sessionStorage.getItem("sitemenu")) || []
        }
    },
    // 获取名称
    computed: {
        title: function () {
            let name = this.$store.getters.siteName
            return name
        }
    },
    // 导航栏数据初始化
    mounted() {
        this.getMeunData()
    },
    methods:{
        // 获取导航数据（分页模块数据）数据
        getMeunData(){
            const value = this.$store.getters.loading
            console.log(value)
            const Code = this.$route.query.Code
            if (!value) {
                this.$store.commit("menu/SITE_MENU", Code)
                this.list = this.$store.getters.siteMenu
            }
        },
        suibianlail(value){
            console.log(value)
            this.$router.push({ name: `${value.key}` })
        }
    }
}
</script>

<style scoped>
#velocityMeasurement-moduleIndex{
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px #bdbcbc;
    border-radius: 3px;
    overflow: hidden;
}
.header {
    height: 100px;
    padding: 0px 20px 0px 20px;
}
.main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.view{
    position: absolute;
    width: 100%;
    height: 100%;
}
.slide-fade-enter-active {
    transition: all 0.8s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>